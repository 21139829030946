<template>
  <div
    :class="[calendarClass, 'vdp-datepicker__calendar']"
    v-show="showDayView"
    :style="calendarStyle"
    @mousedown.prevent
  >
    <slot name="beforeCalendarHeader"></slot>
    <header>
      <span
        @click="isRtl ? nextMonth() : previousMonth()"
        class="prev"
        :id="`wet-${calId}-cal-prev`"
        :class="{'disabled': isLeftNavDisabled}">&lt;</span>
      <span
        class="day__month_btn"
        @click="showMonthCalendar"
        :id="`wet-${calId}-cal-month`"
        :class="allowedToShowView('month') ? 'up' : ''"
      >{{ isYmd ? currYearName : currMonthName }} {{ isYmd ? currMonthName : currYearName }}</span>
      <span
        @click="isRtl ? previousMonth() : nextMonth()"
        class="next"
        :id="`wet-${calId}-cal-next`"
        :class="{'disabled': isRightNavDisabled}">&gt;</span>
    </header>
    <div :class="isRtl ? 'flex-rtl' : ''">
      <span class="cell day-header" v-for="d in daysOfWeek" :key="d.timestamp">{{ d }}</span>
      <template v-if="blankDays > 0">
        <span class="cell day blank" v-for="d in blankDays" :key="d.timestamp"></span>
      </template><!--
      --><span class="cell day"
          v-for="day in days"
          :id="`wet-${calId}-cal-d-${dayCellContent(day)}`"
          :key="day.timestamp"
          :class="dayClasses(day)"
          v-html="dayCellContent(day)"
          @click="selectDate(day)"></span>
    </div>
  </div>
</template>
<script>
import PickerDay from 'vuejs-datepicker/src/components/PickerDay.vue';

export default {
  extends: PickerDay,
  props: {
    calId: {
      type: String,
      default: 'common',
    },
  },
  computed: {
    days() {
      const d = this.pageDate;
      const days = [];
      // set up a new date object to the beginning of the current 'page'
      const dObj = this.useUtc
        ? new Date(Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), 1))
        : new Date(d.getFullYear(), d.getMonth(), 1, d.getHours(), d.getMinutes());
      const daysInMonth = this.utils
        .daysInMonth(this.utils.getFullYear(dObj), this.utils.getMonth(dObj));
      for (let i = 0; i < daysInMonth; i += 1) {
        days.push({
          date: this.utils.getDate(dObj),
          timestamp: dObj.getTime(),
          isSelected: this.isSelectedDate(dObj),
          isDisabled: this.isDisabledDate(dObj),
          isHighlighted: this.isHighlightedDate(dObj),
          isHighlightStart: this.isHighlightStart(dObj),
          isHighlightEnd: this.isHighlightEnd(dObj),
          isToday: this.utils.compareDates(dObj, new Date()),
          isWeekend: this.utils.getDay(dObj) === 0 || this.utils.getDay(dObj) === 6,
          isSaturday: this.utils.getDay(dObj) === 6,
          isSunday: this.utils.getDay(dObj) === 0,
          isFrom: this.isFromTo(dObj, true),
          isTo: this.isFromTo(dObj),
        });
        this.utils.setDate(dObj, this.utils.getDate(dObj) + 1);
      }
      return days;
    },
  },
  methods: {
    dayClasses(day) {
      return {
        selected: day.isSelected,
        disabled: day.isDisabled,
        highlighted: day.isHighlighted,
        today: day.isToday,
        weekend: day.isWeekend,
        sat: day.isSaturday,
        sun: day.isSunday,
        'highlight-start': day.isHighlightStart,
        'highlight-end': day.isHighlightEnd,
        from: day.isFrom,
        to: day.isTo,
      };
    },
    isFromTo(dObj, from) {
      // calc from or to
      const d = this.useUtc
        ? new Date(Date.UTC(dObj))
        : new Date(dObj);
      this.utils.setDate(d, this.utils.getDate(dObj) + (from ? -1 : 1));
      return this.isHighlightedDate(dObj) && !this.isHighlightedDate(d);
    },
  },
};
</script>
